import Breadcrumb from '../components/Breadcrumb/Breadcrumb';
import ContactMap from '../components/ContactMap/ContactMap';
// import ContactForm from '../components/ContactForm/ContactForm';

function ContactUs() {
  return(
    <>
    <Breadcrumb />
    <ContactMap />
    {/* <ContactForm /> */}
    </>
  );
}

export default ContactUs;